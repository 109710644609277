const api = {
    fetchQuestions: async (eventId) => {
        const response = await fetch(process.env.REACT_APP_API_URL + `/events/${eventId}/questions`);

        if (response.ok) {
            const data = await response.json();
            return data;
        }

        return null;
    },
    saveQuestions: async (eventId, questions) => {
        const response = await fetch(process.env.REACT_APP_API_URL + `/events/${eventId}/questions`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(questions)
        });

        if (response.ok) {
            return await response.json();
        }

        return null;
    },
    deleteQuestion: async (eventId, questionId) => {
        const response = await fetch(process.env.REACT_APP_API_URL + `/events/${eventId}/questions/${questionId}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        if (response.ok) {
            return await response.json();
        }

        return null;
    },
    fetchEvents: async () => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/events');

        if (response.ok) {
            const data = await response.json();
            return data;
        }

        return null;
    },
    fetchEvent: async (eventId) => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/events/' + eventId);

        if (response.ok) {
            const data = await response.json();
            return data;
        }

        return null;
    },
    saveEvent: async (eventId, event) => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/events/' + eventId, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(event)
        });

        if (response.ok) {
            return await response.json();
        }

        return null;
    },
    saveGuests: async (eventId, guests) => {
        const response = await fetch(process.env.REACT_APP_API_URL + `/events/${eventId}/guests`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(guests)
        });

        if (response.ok) {
            return await response.json();
        }

        return null;
    },
    fetchGuests: async (eventId) => {
        const response = await fetch(process.env.REACT_APP_API_URL + `/events/${eventId}/guests`);

        if (response.ok) {
            const data = await response.json();
            return data;
        }

        return null;
    },
    disableGuest: async (eventId, guestId) => {
        const response = await fetch(process.env.REACT_APP_API_URL + `/events/${eventId}/guests/${guestId}/disable`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        if (response.ok) {
            return await response.json();
        }

        return null;
    },
    enableGuest: async (eventId, guestId) => {
        const response = await fetch(process.env.REACT_APP_API_URL + `/events/${eventId}/guests/${guestId}/enable`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        if (response.ok) {
            return await response.json();
        }

        return null;
    },
    resetGuest: async (eventId, guestId) => {
        const response = await fetch(process.env.REACT_APP_API_URL + `/events/${eventId}/guests/${guestId}/reset`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        if (response.ok) {
            return await response.json();
        }

        return null;
    },
    deleteGuest: async (eventId, guestId) => {
        const response = await fetch(process.env.REACT_APP_API_URL + `/events/${eventId}/guests/${guestId}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        if (response.ok) {
            return await response.json();
        }

        return null;
    },
    fetchThemes: async (eventId) => {
        const response = await fetch(process.env.REACT_APP_API_URL + `/events/${eventId}/themes`);

        if (response.ok) {
            const data = await response.json();
            return data;
        }

        return null;
    },
    updateTheme: async (eventId, themeId, theme) => {
        const response = await fetch(process.env.REACT_APP_API_URL + `/events/${eventId}/themes/${themeId}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(theme)
        });

        if (response.ok) {
            return await response.json();
        }

        return null;
    },
    fetchResponses: async (eventId) => {
        const response = await fetch(process.env.REACT_APP_API_URL + `/events/${eventId}/responses`);

        if (response.ok) {
            const data = await response.json();
            return data;
        }

        return null;
    },
    downloadGuestsCsv: async (eventId, guests) => {
        return process.env.REACT_APP_API_URL + `/events/${eventId}/guests/download?guests=${guests}`;
    }
}

export default api;
