import Modal from "./Modal";
import QuestionCondition from "./QuestionCondition";
import {useDispatch, useSelector} from "react-redux";
import {addQuestionCondition, fetchQuestions, updateQuestionCondition} from "../redux/questions-slice";
import {useState} from "react";
import {useParams} from "react-router-dom";

const QuestionConditionEditor = ({question, disabled}) => {
    const {eventId} = useParams();
    const dispatch = useDispatch();
    const {questions: {questions}} = useSelector((state) => state.rsvp);

    const [showModal, setShowModal] = useState(false);
    const [addingCondition, setAddingCondition] = useState(false);

    return <>
        <div className="question-condition-edit">
            <textarea
                wrap="off"
                rows={Math.min(question.conditions?.length, 4) + 1}
                className="form-control"
                disabled={true}
                value={
                    question.conditions
                        ?.map(value =>
                            `${value?.questionId && questions.find(f => f.id === value.questionId)?.heading} ${value?.conditionType} ${value?.answerId && questions.find(f => f.id === value.questionId).answers.find(f => f.id === value.answerId)?.answerText}`
                        ).join("\n")
                }
            />
            <button disabled={disabled} className="btn btn-secondary" onClick={() => setShowModal(true)}>Edit</button>
        </div>
        {showModal &&
            <Modal
                isOpen={showModal}
                onClose={() => {
                    setShowModal(false);
                    dispatch(fetchQuestions(eventId));
                }}
                header={<>
                    <h5 className="modal-title">Edit display condition</h5>
                </>}
                body={
                    <>
                        <p>Add conditions for when this question is shown - ie evening guest only/selected allergies
                            etc</p>
                        <div className="question-condition-list">
                            {
                                question.conditions
                                    ?.reduce((acc, cur, i) => {
                                        acc.push(
                                            <QuestionCondition
                                                value={cur}
                                                question={question}
                                                onChange={(value) => {
                                                    dispatch(updateQuestionCondition({
                                                        index: i,
                                                        questionId: question.id,
                                                        condition: value
                                                    }));
                                                }}
                                            />
                                        );
                                        if (i < question.conditions.length - 1) acc.push(<h5>OR</h5>);
                                        return acc;
                                    }, [])
                            }
                            {addingCondition && <QuestionCondition editing={true}
                                                                   question={question}
                                                                   onChange={(value) => {
                                                                       dispatch(addQuestionCondition({
                                                                           questionId: question.id,
                                                                           condition: value
                                                                       }));
                                                                       setAddingCondition(false);
                                                                   }}/>}
                            {!addingCondition &&
                                <button className="btn btn-primary"
                                        onClick={() => setAddingCondition(true)}>Add</button>}
                        </div>
                    </>
                }
                footer={<>
                    <button type="button" className="btn btn-primary" onClick={() => setShowModal(false)}>Save changes
                    </button>
                </>}
            />
        }
    </>
}

export default QuestionConditionEditor;
