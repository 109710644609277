import {useDispatch, useSelector} from "react-redux";
import {useEffect, useMemo, useState} from "react";
import {fetchResponses} from "../redux/responses-slice";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import moment from "moment";
import {TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted} from "react-icons/ti";
import ReactSelect, {components} from "react-select";
import {GridLoader} from "react-spinners";

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const ValueContainer = (props) => {
    const length = props.getValue().length;
    const children = props.children;

    return length ? (
        <components.ValueContainer {...props}>
            <>
                {length} selected
                {children.slice(-1)}
            </>
        </components.ValueContainer>
    ) : (
        <components.ValueContainer {...props}>{children}</components.ValueContainer>
    );
};

const Responses = () => {
    const {eventId} = useParams();
    const state = useSelector((state) => state.rsvp);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [sortColumn, setSortColumn] = useState("name");
    const [sortHandler, setSortHandler] = useState(() => (a, b) => a.guestName?.localeCompare(b.guestName));
    const [sortAscending, setSortAscending] = useState(true);

    const [columnFilters, setColumnFilters] = useState({});

    const responses = state.responses?.responses;
    const questions = state.questions?.questions;
    const guests = state.guests?.guests;

    const questionsToDisplay = questions?.filter(f => !!f.answerType && f.answerType !== "none");

    const sortedResponses = responses && guests && Object.keys(responses)
        .map(key => ({
            guestName: guests.find(f => f.id === key)?.name,
            timestamp: moment(responses[key].reduce((acc, cur) => moment(cur.timestamp).isAfter(acc) ? acc : cur.timestamp)).format("DD MMM YYYY"),
            key,
            ...questionsToDisplay.reduce((acc, cur) => {
                acc[cur.id] = responses[key].find(f => f.questionId === cur.id)?.answerText;
                return acc;
            }, {})
        }))
        .sort(sortHandler) || [];

    const filteredResponses = sortedResponses.filter(f => {
        const props = Object.keys(f);

        for (let prop of props) {
            if (columnFilters[prop] &&
                !columnFilters[prop].find(c => c.value === f[prop] || (c.value === "(blank)" && !f[prop]))
            ) {
                return false;
            }
        }

        return true;
    });

    const filterOptions = useMemo(() =>
            [{id: "timestamp"}].concat(questionsToDisplay).reduce((obj, m) => {
                obj = {
                    ...obj,
                    [m.id]: Object.keys(sortedResponses.filter(f => f[m.id]).reduce((acc, cur) => ({
                        ...acc,
                        [cur[m.id]]: cur[m.id]
                    }), {}))
                        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                        .concat(["(blank)"])
                        .map(m => ({value: m, label: m}))
                }

                return obj;
            }, {})
        , [sortedResponses, questionsToDisplay]);

    const sortResponses = (prop) => {
        let ascending = sortAscending;
        if (prop === sortColumn) {
            setSortAscending(!sortAscending);
            ascending = !sortAscending;
        } else {
            ascending = true;
            setSortAscending(ascending);
            setSortColumn(prop);
        }

        const up = ascending ? -1 : 1;
        const down = ascending ? 1 : -1;

        setSortHandler(() => (a, b) => {
            if ((a[prop] || "") < (b[prop] || "")) {
                return up;
            }
            if ((a[prop] || "") > (b[prop] || "")) {
                return down;
            }
            return 0;
        });
    }

    const downloadResponses = () => {
        // download sortedResponses as a csv
        const csv = [
            ["Guest Name", "Date", ...questionsToDisplay.map(f =>
                `"${f.heading}"`
            )]
        ].concat(filteredResponses.map(f => [`"${f.guestName}"`, f.timestamp, ...questionsToDisplay.map(q => `"${f[q.id] || ""}"`)]));

        const csvContent = "data:text/csv;charset=utf-8," + csv.map(e => e.join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);

        window.open(encodedUri);
    }

    useEffect(() => {
        dispatch(fetchResponses(eventId));
    }, [])

    const isLoading = state.events.loading || state.questions.loading || state.guests.loading || state.responses.loading || state.themes.loading;

    const renderTable = (justHeaders) => (
        <table className="table table-bordered table-striped">
            <thead>
            <tr className="align-top sortable" style={{backgroundColor: "white"}}>
                {/*<th style={{width: colWidth + "%"}}>Id</th>*/}
                <th onClick={() => sortResponses("guestName")} style={{minWidth: "20vw"}}>
                    Name
                    {sortColumn === "guestName" && sortAscending && <TiArrowSortedUp/>}
                    {sortColumn === "guestName" && !sortAscending && <TiArrowSortedDown/>}
                    {sortColumn !== "guestName" && <TiArrowUnsorted/>}
                </th>
                <th onClick={() => sortResponses("timestamp")} style={{minWidth: "20vw"}}>
                    Date
                    {sortColumn === "timestamp" && sortAscending && <TiArrowSortedUp/>}
                    {sortColumn === "timestamp" && !sortAscending && <TiArrowSortedDown/>}
                    {sortColumn !== "timestamp" && <TiArrowUnsorted/>}
                </th>
                {
                    questionsToDisplay.map(m => <th key={m.id}
                                                    onClick={() => sortResponses(m.id)}
                                                    style={{minWidth: "20vw"}}>
                        <div>
                            {m.heading}
                            {sortColumn === m.id && sortAscending && <TiArrowSortedUp/>}
                            {sortColumn === m.id && !sortAscending && <TiArrowSortedDown/>}
                            {sortColumn !== m.id && <TiArrowUnsorted/>}
                        </div>
                        <div><small className="lead">{m.subHeading}</small></div>
                        {/*{m.body && <div><small className="text-muted">{m.body}</small></div>}*/}
                    </th>)
                }
            </tr>
            <tr>
                <th onClick={() => null}>
                    Filter:
                </th>
                <th>
                    <select className="form-select">
                        {
                            Object.keys(filteredResponses.reduce((acc, cur) => ({
                                ...acc,
                                [cur.timestamp]: cur.timestamp
                            }), {}))
                                .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                                .map(m => <option>{m}</option>)
                        }
                    </select>

                </th>
                {
                    questionsToDisplay.map(m => <th key={m.id + "_filter"}>
                        <ReactSelect
                            options={
                                filterOptions[m.id] || []
                            }
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{
                                Option,
                                ValueContainer
                            }}
                            onChange={(selectedValues) => {
                                const selected = selectedValues.filter(f => f.value);

                                setColumnFilters({
                                    ...columnFilters,
                                    [m.id]: selected.length > 0 ? selected : filterOptions[m.id]
                                });
                            }}
                            allowSelectAll={true}
                            value={columnFilters[m.id] || filterOptions[m.id]}
                        />
                    </th>)
                }
            </tr>
            </thead>
            <tbody style={{visibility: justHeaders ? "hidden" : null}}>
            {
                responses && guests &&
                filteredResponses
                    .map(({guestName, key}) =>
                        <tr key={key}>
                            {/*<td>{key}</td>*/}
                            <td>{guestName}</td>
                            <td>{
                                moment(responses[key].reduce((acc, cur) => moment(cur.timestamp).isAfter(acc) ? acc : cur.timestamp)).format("DD MMM YYYY")
                            }</td>
                            {
                                questionsToDisplay.map(m => <td
                                    key={m.id}>{responses[key].find(f => f.questionId === m.id)?.answerText}</td>)
                            }
                        </tr>)
            }
            </tbody>
        </table>);

    return <div className="responses-container">
        <div className="toolbar">
            <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio1"
                    autoComplete="off"
                    checked={false}
                    onChange={() => navigate("../summary")}
                />
                <label
                    className="btn btn-outline-primary"
                    htmlFor="btnradio1"
                >Summary</label>

                <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio2"
                    autoComplete="off"
                    checked={true}
                />
                <label
                    className="btn btn-outline-primary"
                    htmlFor="btnradio2"
                >Detailed</label>
            </div>

            <button className="btn btn-secondary" onClick={() => downloadResponses()}>Download current view as
                CSV
            </button>
        </div>

        {isLoading ? <div className="loading-spinner">
                <GridLoader size="40px"/>
            </div>
            : <div style={{position: "relative", width: "100%", height: "100%"}}>
                <div style={{width: "100%", height: "100%", overflow: "auto"}}>
                    {renderTable()}
                </div>
                {/*{renderTable(true)}*/}
            </div>
        }
    </div>
};

export default Responses;
