import {resolveColor} from "../services/style-helper";
import Question from "./Question";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

const PreviewQuestions = () => {
    const params = useParams();

    const state = useSelector((state) => state.rsvp);

    const {
        questions: {questions},
        events: {events}
    } = state;

    const {themes: {themes}} = state;

    // load themes
    const event = (events || []).find(f => f.id === params.eventId);
    const selectedThemeName = event?.theme;
    const selectedTheme = themes && themes.find(f => f.name === selectedThemeName)?.theme;

    return <div className="all-question-previews">
        {questions && questions.map(m => <div
            key={m.id}
            className="question-preview"
            style={{
                backgroundColor: resolveColor(m.backgroundColor, selectedTheme?.colors),
                color: resolveColor(m.textColor, selectedTheme?.colors)
            }}
        >
            <div className="page">
                <Question
                    question={m}
                    theme={selectedTheme}
                    answer={null}
                    answerChanged={() => null}
                    guests={[]}
                    guestListUpdated={() => null}
                />
            </div>
        </div>)}
    </div>
}

export default PreviewQuestions;
