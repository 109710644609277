import {useState} from "react";
import ReactMarkdown from "react-markdown";

const Question = (
    {
        question,
        theme,
        answer,
        answerChanged,
        guests,
        guestListUpdated,
    }) => {

    const [showCollapse, setShowCollapse] = useState(false);

    function LinkRenderer(props) {
        return (
            <a href={props.href} target="_blank" rel="noreferrer">
                {props.children}
            </a>
        );
    }

    const renderAnswers = (question) => {
        switch (question.answerType) {
            case "single_choice":
            case "multiple_choice":
            case "invitees":
                let answers = question.answers;
                if (question.answerType === "invitees") {
                    answers = guests && guests.map(m => ({answerText: m}));
                }

                if (!answers) return null;

                return <div className="answer-list">
                    {answers && answers.map(m =>
                        <label key={m.id}>
                            <input
                                checked={answer?.id === m.id}
                                onChange={(e) => {
                                    if (question.answerType === "invitees") {
                                        guestListUpdated(m)
                                    } else {
                                        answerChanged(m)
                                    }
                                }}
                                type={question.answerType === "single_choice" ? "radio" : "checkbox"}
                                name="answer"
                                value={m}
                            />
                            <span>
                                {m.answerText}
                                {m.helpText ? <p>{m.helpText}</p> : null}
                            </span>
                        </label>
                    )}
                </div>
            case "free_text":
                return <div className="answer-list">
                    <textarea
                        rows={6}
                        value={answer?.freeText}
                        onChange={(e) =>
                            answerChanged({
                                freeText: e.target.value
                            })
                        }
                    />
                </div>;
        }
    }

    return <>
        <div
            className="page-heading"
        >
            {question.heading && <h1>{question.heading}</h1>}
            {question.subHeading && <h2>{question.subHeading}</h2>}
            {question.body ? <ReactMarkdown>{question.body}</ReactMarkdown> : null}
            {question.collapseHeading && question.collapseText ? <>
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">{question.collapseHeading}</h5>
                        <p className="card-text"><ReactMarkdown
                            components={{a: LinkRenderer}}>{question.collapseText}</ReactMarkdown></p>
                        {/*<a href="#" className="btn btn-primary">Go somewhere</a>*/}
                    </div>
                </div>
            </> : null}
        </div>
        {renderAnswers(question)}
    </>
}

export default Question;
