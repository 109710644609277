import Modal from "./Modal";
import {useState} from "react";
import {CircleLoader} from "react-spinners";

const EditThemeModal = ({show, onClose, onSave, defaultImage}) => {

    const [encodedImage, setEncodedImage] = useState(defaultImage || "");
    const [showSaveAnimation, setShowSaveAnimation] = useState(false);

    const storeImage = (files) => {
        // base 64 encode the image

        if (files && files.length > 0) {
            const reader = new FileReader();

            reader.readAsDataURL(files[0]);

            reader.onload = () => {
                setEncodedImage(reader.result);
            }
        }
    }

    return <Modal
        isOpen={show}
        onClose={onClose}
        closeLabel="Cancel"
        disableControls={showSaveAnimation}
        header={<>
            <h5 className="modal-title">Edit Logo</h5>
        </>}
        body={<>
            <div className="form-group">
                <div className="logo-preview">
                    <img src={encodedImage}/>
                </div>
                <dl className="row">
                    <dt className="col-sm-4">Name</dt>
                    <dd className="col-sm-8">
                        <label className="form-label">
                            <input type="file" placeholder="" className="form-control"
                                   onChange={(e) => storeImage(e.target.files)}/>
                        </label>
                    </dd>
                </dl>
            </div>
        </>}
        footer={
            <button
                className="btn btn-primary btn-flex"
                onClick={() => {
                    setShowSaveAnimation(true);
                    onSave(encodedImage);
                }}
                disabled={showSaveAnimation}
            >Save{showSaveAnimation && <CircleLoader size="1rem" color="white"/>}</button>
        }
    />
}

export default EditThemeModal;
