import Modal from "./Modal";
import {useState} from "react";

const EventContactModal = ({show, onClose, defaultContactName, defaultContactEmail, defaultContactNumber, onSave}) => {
    const [contactName, setContactName] = useState(defaultContactName || "");
    const [contactEmail, setContactEmail] = useState(defaultContactEmail || "");
    const [contactNumber, setContactNumber] = useState(defaultContactNumber || "");

    return <Modal
        isOpen={show}
        onClose={onClose}
        closeLabel="Cancel"
        header={<>
            <h5 className="modal-title">Edit Event Contact Information</h5>
        </>}
        body={<>
            <div className="form-group">
                <dl className="row">
                    <dt className="col-sm-4">Name</dt>
                    <dd className="col-sm-8">
                        <label className="form-label">
                            <input type="text" placeholder="" className="form-control"
                                   onChange={(e) => setContactName(e.target.value)} value={contactName}/>
                        </label>
                    </dd>
                </dl>
            </div>
            <div className="form-group">
                <dl className="row">
                    <dt className="col-sm-4">Email</dt>
                    <dd className="col-sm-8">
                        <label className="form-label">
                            <input type="text" placeholder="" className="form-control"
                                   onChange={(e) => setContactEmail(e.target.value)} value={contactEmail}/>
                        </label>
                    </dd>
                </dl>
            </div>
            <div className="form-group">
                <dl className="row">
                    <dt className="col-sm-4">Phone Number</dt>
                    <dd className="col-sm-8">
                        <label className="form-label">
                            <input type="text" placeholder="" className="form-control"
                                   onChange={(e) => setContactNumber(e.target.value)} value={contactNumber}/>
                        </label>
                    </dd>
                </dl>
            </div>
        </>}
        footer={<button className="btn btn-primary" onClick={() => onSave({
            contactName,
            contactEmail,
            contactNumber
        })}>Save</button>}
    />
}

export default EventContactModal;
