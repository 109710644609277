import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

const QuestionCondition = ({value, onChange, editing, question}) => {
    const dispatch = useDispatch();
    const {questions: {questions}} = useSelector((state) => state.rsvp);
    const [isEditing, setIsEditing] = useState(!!editing);
    const [selectedQuestion, setSelectedQuestion] = useState(value?.questionId);
    const [selectedCondition, setSelectedCondition] = useState(value?.conditionType || "is");
    const [selectedAnswer, setSelectedAnswer] = useState(value?.answerText);

    useEffect(() => {
        setSelectedAnswer(
            questions.find(
                f => f.id === selectedQuestion)?.answers[0]?.id)
    }, [selectedQuestion]);

    return <div className="question-condition">
        {!isEditing &&
            <div className="input-group">
                <span className="input-group-text">When</span>
                <div className="form-floating">
                    <input disabled type="text" className="form-control" id="floatingInputGroup1"
                           placeholder="Username"/>
                    <label htmlFor="floatingInputGroup1">
                        {value &&
                            `${value?.questionId && questions.find(f => f.id === value.questionId)?.heading} ${value?.conditionType} ${value?.answerId && questions.find(f => f.id === value.questionId).answers.find(f => f.id === value.answerId)?.answerText}`}
                    </label>
                </div>
                <button className="btn btn-primary" onClick={() => setIsEditing(true)}>Edit</button>
            </div>
        }
        {
            isEditing &&
            <div className="card">
                <div className="card-body">
                    <h5>When</h5>
                    <select className="form-select" onChange={(e) => setSelectedQuestion(e.target.value)} value={selectedQuestion}>
                        <option></option>
                        {
                            questions.filter(f => f.id !== question.id)
                                .map(m => <option key={m.id} value={m.id}>({m.heading}) - {m.subHeading}</option>)
                        }
                    </select>

                    {selectedQuestion &&
                        <>
                            <div>
                                <div className="btn-group">
                                    <label
                                        className={`btn btn-sm btn-${selectedCondition === "is" ? "" : "outline-"}primary`}>
                                        <input
                                            type="radio"
                                            className="btn-check"
                                            name="condition"
                                            autoComplete="off"
                                            value="is"
                                            onChange={(e) => setSelectedCondition(e.target.value)}
                                        />
                                        Is</label>
                                    <label
                                        className={`btn btn-sm btn-${selectedCondition === "isnot" ? "" : "outline-"}primary`}>
                                        <input
                                            type="radio"
                                            className="btn-check"
                                            name="condition"
                                            autoComplete="off"
                                            value="isnot"
                                            onChange={(e) => setSelectedCondition(e.target.value)}
                                        />
                                        Is not</label>
                                    {/*<label className="btn btn-primary">*/}
                                    {/*    <input type="radio" className="btn-check" name="condition" autoComplete="off"/>*/}
                                    {/*    Checked</label>*/}
                                </div>
                            </div>
                            <select className="form-select" value={selectedAnswer}
                                    onChange={(e) => setSelectedAnswer(e.target.value)}>
                                {
                                    (questions.find(f => f.id === selectedQuestion)?.answers || [])
                                        .map(m => <option key={m.id} value={m.id}>{m.answerText}</option>)
                                }
                            </select>
                        </>
                    }
                    <div className="actions">
                        <button className="btn btn-sm btn-primary" onClick={() => {
                            setIsEditing(false);
                            onChange && onChange({
                                questionId: selectedQuestion,
                                conditionType: selectedCondition,
                                answerId: selectedAnswer
                            });
                        }}>Done
                        </button>
                        <button className="btn btn-sm btn-secondary" onClick={() => setIsEditing(false)}>Cancel</button>
                    </div>
                </div>
            </div>
        }
    </div>
};

export default QuestionCondition;
