import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import responsesApi from 'services/responses-api';
import {v4} from "uuid";

export const fetchQuestions = createAsyncThunk(
    'questions/fetchAll',
    async (eventId, thunkAPI) => {
        const response = await responsesApi.fetchQuestions(eventId);
        return response;
    }
)

export const saveQuestions = createAsyncThunk(
    'questions/save',
    async ({eventId, questions}, thunkAPI) => {
        const response = await responsesApi.saveQuestions(eventId, questions);

        if (response.ok) {
            return response.responses;
        } else {
            return null;
        }
    }
)

export const deleteQuestion = createAsyncThunk(
    'questions/delete',
    async ({eventId, question}, thunkAPI) => {
        const response = await responsesApi.deleteQuestion(eventId, question.id);
        if (response.ok) {
            return question;
        } else {
            return null;
        }
    }
);

const initialState = {
    loading: false,
    error: false,
    questions: [],
    dirty: false
}

const reindexQuestionsAndAnswers = (state) => {
    state.questions = state.questions
    .map((m, i) => ({
            ...m,
            order: i,
            answers: m.answers && m.answers
            .map((a, j) => ({...a, order: j}))
        }
    ));
}

const arrayMove = (array, from, to) => {
    return array.slice(), array.splice(to, 0, ...array.splice(from, 1)), array;
}

export const questionsSlice = createSlice({
    name: 'questions',
    initialState,
    reducers: {
        addQuestion: (state, action) => {
            state.questions = state.questions || [];

            if (action.payload?.order >= 0) {
                // insert at index
                state.questions.splice(action.payload.order + 1, 0, {});
            } else {
                state.questions.push({});
            }

            reindexQuestionsAndAnswers(state);

            state.dirty = true;
        },
        updateQuestion: (state, action) => {
            state.questions[action.payload.index] = action.payload.question;

            reindexQuestionsAndAnswers(state);


            state.dirty = true;
        },
        moveQuestionUp: (state, action) => {
            const index = state.questions.indexOf(state.questions.find(f => f.id === action.payload.id));
            state.questions = arrayMove(state.questions, index, index - 1);

            reindexQuestionsAndAnswers(state);

            state.dirty = true;
        },
        moveQuestionDown: (state, action) => {
            const index = state.questions.indexOf(state.questions.find(f => f.id === action.payload.id));
            state.questions = arrayMove(state.questions, index, index + 1);

            reindexQuestionsAndAnswers(state);

            state.dirty = true;
        },
        addQuestionCondition: (state, action) => {
            const question = state.questions.find(f => f.id === action.payload.questionId);
            question.conditions = question.conditions || [];
            question.conditions.push(action.payload.condition);

            state.dirty = true;
        },
        updateQuestionCondition: (state, action) => {
            const question = state.questions.find(f => f.id === action.payload.questionId);
            question.conditions = question.conditions || [];
            question.conditions[action.payload.index] = action.payload.condition;

            state.dirty = true;
        },
        moveAnswerUp: (state, action) => {
            const question = state.questions.find(f => f.answers.some(a => a.id === action.payload));
            const answer = question?.answers.find(a => a.id === action.payload);

            if (answer && question) {
                const index = question.answers.indexOf(answer);
                if (index > 0) {
                    question.answers = arrayMove(question.answers, index, index - 1);
                }

                reindexQuestionsAndAnswers(state);
                state.dirty = true;
            }
        },
        moveAnswerDown: (state, action) => {
            const question = state.questions.find(f => f.answers.some(a => a.id === action.payload));
            const answer = question?.answers.find(a => a.id === action.payload);

            if (answer && question) {
                const index = question.answers.indexOf(answer);
                if (index < question.answers.length - 1) {
                    question.answers = arrayMove(question.answers, index, index + 1);
                }

                reindexQuestionsAndAnswers(state);
                state.dirty = true;
            }
        }
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(fetchQuestions.pending, (state, action) => {
            // Add user to the state array
            state.loading = true;
            state.error = false;
        });

        builder.addCase(fetchQuestions.fulfilled, (state, action) => {
            // Add user to the state array
            state.loading = false;
            state.error = false;
            state.dirty = false;

            state.questions = (action.payload || [])
            .sort((a, b) => a.order - b.order)
            .map(m => ({...m, answers: m.answers.sort((a, b) => a.order - b.order)}))
        });

        builder.addCase(fetchQuestions.rejected, (state, action) => {
            // Add user to the state array
            state.loading = false;
            state.error = true;

            console.error(action);
        });

        builder.addCase(saveQuestions.fulfilled, (state, action) => {
            // Add user to the state array
            state.loading = false;
            state.error = false;
            state.dirty = false;

            state.questions = (action.payload || [])
            .sort((a, b) => a.order - b.order)
            .map(m => ({...m, answers: m.answers.sort((a, b) => a.order - b.order)}))
        });

        builder.addCase(saveQuestions.rejected, (state, action) => {
            console.error(action);
        });

        builder.addCase(deleteQuestion.fulfilled, (state, action) => {
            state.questions = state.questions.filter(f => f.id !== action.meta.arg.question.id);
        });
    },
})

// Action creators are generated for each case reducer function
export const {
    addQuestion,
    updateQuestion,
    moveQuestionDown,
    moveQuestionUp,
    addQuestionCondition,
    updateQuestionCondition,
    moveAnswerDown,
    moveAnswerUp
} = questionsSlice.actions

export default questionsSlice.reducer
