import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import responsesApi from 'services/responses-api';

export const fetchThemes = createAsyncThunk(
    'theme/fetchAll',
    async (eventId, thunkAPI) => {
        const response = await responsesApi.fetchThemes(eventId);
        return response;
    }
);

export const updateTheme = createAsyncThunk(
    'theme/update',
    async (params, thunkAPI) => {
        const response = await responsesApi.updateTheme(params.eventId, params.themeId, params.theme);
        return response;
    }
);

const initialState = {
    loading: false,
    error: false,
    themes: []
}

export const themesSlice = createSlice({
    name: 'questions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(fetchThemes.pending, (state, action) => {
            // Add user to the state array
            state.loading = true;
            state.error = false;
        });

        builder.addCase(fetchThemes.fulfilled, (state, action) => {
            // Add user to the state array
            state.loading = false;
            state.error = false;

            state.themes = action.payload;
        });

        builder.addCase(fetchThemes.rejected, (state, action) => {
            // Add user to the state array
            state.loading = false;
            state.error = true;

            console.error(action);
        });

        builder.addCase(updateTheme.pending, (state, action) => {
            // Add user to the state array
            state.loading = true;
            state.error = false;
        });

        builder.addCase(updateTheme.fulfilled, (state, action) => {
            // Add user to the state array
            state.loading = false;
            state.error = false;

            const updatedTheme = action.payload.response;

            const index = state.themes.findIndex((theme) => theme.id === updatedTheme.id);
            state.themes[index] = updatedTheme;
        });
    },
})

// Action creators are generated for each case reducer function
export const {} = themesSlice.actions

export default themesSlice.reducer
