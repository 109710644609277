import {resolveColor} from "../services/style-helper";
import {ReactComponent as Logo} from "assets/images/monogram-new.svg";

const WelcomeSignedIn = ({user, config, logo, confirmUser, clearUser}) => {
    const {theme: {colors, welcomePage}} = config || {theme: {}};

    return user && config && colors && welcomePage && <div className="full-size-container" style={{
        backgroundColor: resolveColor(welcomePage.background, colors),
        color: resolveColor(welcomePage.color, colors)
    }}>
        <div className="page">
            <img className="logo" src={logo} style={{color: resolveColor(welcomePage.color, colors)}}/>
            <div className="page-heading">
                <h1>Welcome</h1>
                <h2>{user.current.name}!</h2>
            </div>
            <div>
                <button className="btn btn-primary" onClick={() => confirmUser()}>RSVP</button>
            </div>
            <a href="/" onClick={() => clearUser()}>Not you?</a>
        </div>
    </div>
};

export default WelcomeSignedIn;
