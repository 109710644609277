import {QRCode} from "react-qrcode-logo";

const RsvpQrCode = ({settings}) => {
    const {url, ecLevel, style, cornerStyle, logoUrl, logoSize, logoOpacity} = settings || {};

    return <QRCode
        value={url || "http://example.com"}
        ecLevel={ecLevel}
        qrStyle={style}
        eyeRadius={
            cornerStyle === "square" ? 0
                : cornerStyle === "round" ? 999
                    : cornerStyle === "teardrop" ? [[10, 10, 0, 10], [10, 10, 10, 0], [10, 0, 10, 10]] : 0
        }
        logoImage={logoUrl}
        logoHeight={logoSize}
        logoWidth={logoSize}
        // logoPadding={2}
        removeQrCodeBehindLogo={false}
        logoPaddingStyle={"circle"}
        logoOpacity={logoOpacity}
    />
};

export default RsvpQrCode;
