import {resolveColor} from "../services/style-helper";
import Question from "./Question";
import {useEffect, useRef, useState} from "react";
import useResize from "./ResizeObserverHook";

const QuestionPreview = ({question, theme, selected}) => {
    const [showAddQuestion, setShowAddQuestion] = useState(false);
    const [calculatedZoom, setCalculatedZoom] = useState(1);

    const previewRef = useRef();

    const size = useResize(previewRef);

    useEffect(() => {
        calculateZoom();
    }, [size])

    const dummyGuests = [
        "Guest 1",
        "Guest 2",
        "Guest 3",
    ];

    const calculateZoom = () => {
        setCalculatedZoom(previewRef.current?.offsetWidth / 500);
    };

    return <>
        <div
            className={`question-preview-wrapper${selected ? " selected" : ""}`}
            style={{
                backgroundColor: resolveColor(question?.backgroundColor || "white", theme?.colors),
                color: resolveColor(question?.textColor || "black", theme?.colors)
            }}
            ref={previewRef}
            // onMouseLeave={() => setShowAddQuestion(false)}
        >
            <div className="page" style={{zoom: calculatedZoom}} >
                <Question
                    question={question}
                    theme={theme}
                    answer={null}
                    answerChanged={() => null}
                    guests={dummyGuests}
                    guestListUpdated={() => null}
                />
            </div>
        </div>
        {showAddQuestion && <div className="add-question">+</div>}
    </>
}

export default QuestionPreview;
