import React, {useCallback, useEffect} from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
// import EventsList from "./components/EventsList";
import QuestionPreviews from "./components/QuestionPreviews";
import Dashboard from "./components/Dashboard";
import RouteError from "./components/RouteError";
import EventSummary from "./components/EventSummary";
import QuestionEditor from "./components/QuestionEditor";
import Guests from "./components/Guests";
import Responses from "./components/Responses";
import ResponsesSummary from "./components/ResponsesSummary";
import ResponsesDetail from "./components/ResponsesDetail";

const router = createBrowserRouter([
    {
        path: "/",
    },
    // {
    //     path: "/events",
    //     element: <EventsList />,
    // },
    {
        path: "events/:eventId/questions/preview",
        element: <QuestionPreviews/>
    },
    {
        path: "/events/:eventId",
        element: <Dashboard/>,
        errorElement: <RouteError/>,
        children: [
            {
                path: "summary",
                element: <EventSummary/>
            },
            {
                path: "questions",
                element: <QuestionEditor/>,
            },
            {
                path: "guests",
                element: <Guests/>
            },
            {
                path: "responses",
                element: <Responses/>,
                children: [
                    {
                        path: "summary",
                        element: <ResponsesSummary/>
                    },
                    {
                        path: "detail",
                        element: <ResponsesDetail/>
                    }
                ]
            }
        ]
    },
]);

function App() {
    // const setTheme = (window) => {
    //     const theme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)') ? "dark" : "light";
    //
    //     window.document.documentElement.setAttribute('data-bs-theme', theme);
    // }
    //
    // const listener = useCallback(event => {
    //     setTheme(window);
    // }, [document, window]);
    //
    // useEffect(() => {
    //     const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    //
    //     mediaQuery.addEventListener('change', listener);
    //
    //     setTheme(window);
    //
    //     // remove the event listener created above
    //     return () => mediaQuery.removeEventListener('change', listener);
    // }, [listener])

    return (
        <RouterProvider router={router}/>
    );
}

export default App;
