import {configureStore} from '@reduxjs/toolkit';
import {combineReducers} from 'redux'
import responses from "./responses-slice";
import questions from "./questions-slice";
import events from "./events-slice";
import themes from "./theme-slice";
import guests from "./guests-slice";

const reducer = combineReducers({
    responses,
    questions,
    events,
    themes,
    guests
})

export const store = configureStore({
    reducer: {
        rsvp: reducer,
    },
});
