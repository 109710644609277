import {cloneDeep} from "lodash";
import {updateQuestion, moveQuestionUp, moveQuestionDown, moveAnswerUp, moveAnswerDown} from "../redux/questions-slice";
import {useDispatch} from "react-redux";
import QuestionConditionEditor from "./QuestionConditionEditor";

import {AiOutlineArrowUp, AiOutlineArrowDown} from "react-icons/ai";

const QuestionPropEditor = (
    {
        question: input,
        questions,
        questionUpdated,
        theme: selectedTheme
    }) => {

    const dispatch = useDispatch();

    const question = cloneDeep(input || {});
    const theme = {
        ...cloneDeep(selectedTheme),
        colors: {
            "": "",
            "black": "black",
            "white": "white",
            ...cloneDeep(selectedTheme?.colors || {})
        }
    };

    const disabled = !input;

    return <div className="question-props-editor">
        <label className="form-label">
            Order <AiOutlineArrowUp onClick={() => dispatch(moveQuestionUp(question))}/>
            <AiOutlineArrowDown onClick={() => dispatch(moveQuestionDown(question))}/>
        </label>
        <label className="form-label">
            <span>Heading</span>
            <input
                disabled={disabled}
                className="form-control"
                type="text"
                value={question.heading || ""}
                onChange={(e) => {
                    question.heading = e.target.value;
                    questionUpdated && questionUpdated(question);
                }}
            />
        </label>
        <label className="form-label">
            <span>Sub heading</span>
            <textarea
                disabled={disabled}
                className="form-control"
                rows={4}
                value={question.subHeading || ""}
                onChange={(e) => {
                    question.subHeading = e.target.value;
                    questionUpdated && questionUpdated(question);
                }}
            />
        </label>
        <label className="form-label">
            <span>Body</span>
            <textarea
                disabled={disabled}
                className="form-control"
                rows={4}
                value={question.body || ""}
                onChange={(e) => {
                    question.body = e.target.value;
                    questionUpdated && questionUpdated(question);
                }}
            />
        </label>
        <label className="form-label">
            <span>Additional info heading</span>
            <input
                disabled={disabled}
                className="form-control"
                type="text"
                value={question.collapseHeading || ""}
                onChange={(e) => {
                    question.collapseHeading = e.target.value;
                    questionUpdated && questionUpdated(question);
                }}
            />
        </label>
        <label className="form-label">
            <span>Additional info body</span>
            <textarea
                disabled={disabled}
                className="form-control"
                rows={4}
                value={question.collapseText || ""}
                onChange={(e) => {
                    question.collapseText = e.target.value;
                    questionUpdated && questionUpdated(question);
                }}
            />
        </label>
        <label className="form-label">
            <span>Display condition</span>
            <QuestionConditionEditor question={question} disabled={disabled}/>
        </label>
        {/*{(!question.answerType || question.answerType === "none") &&*/}
        {/*    <div>*/}
        {/*        <label className="form-label">*/}
        {/*            <span>Next question</span>*/}
        {/*            <select value={question.nextQuestion} className="form-select">*/}
        {/*                {questions && questions.map(q =>*/}
        {/*                    <option*/}
        {/*                        key={q.id}*/}
        {/*                        value={q.id}*/}
        {/*                        onChange={(e) => {*/}
        {/*                            question.nextQuestion = e.target.value;*/}
        {/*                            questionUpdated && questionUpdated(question);*/}
        {/*                        }}*/}
        {/*                    >{q.heading}</option>*/}
        {/*                )}*/}
        {/*            </select>*/}
        {/*        </label>*/}
        {/*    </div>*/}
        {/*}*/}
        <label className="form-label">
            <span>Background colour</span>
            <div className="theme-preview">
                <div className="colours inline selectable">
                {theme && theme.colors && Object.keys(theme.colors).filter(f => f).map(c =>
                    <div
                        className={`theme-colour${question.backgroundColor === c ? " selected" : ""}`}
                        style={{opacity: disabled ? 0.5 : 1}}
                        onClick={() => {
                            if (!disabled) {
                                question.backgroundColor = c;
                                questionUpdated && questionUpdated(question);
                            }
                        }}
                    >
                        <div className="colour" style={{backgroundColor: theme.colors[c]}}></div>
                    </div>
                )}
                </div>
                <span>{question.backgroundColor}</span>
            </div>
        </label>
        <label className="form-label">
            <span>Text colour</span>
            <div className="theme-preview">
                <div className="colours inline selectable">
                    {theme && theme.colors && Object.keys(theme.colors).filter(f => f).map(c =>
                        <div
                            className={`theme-colour${question.textColor === c ? " selected" : ""}`}
                            style={{opacity: disabled ? 0.5 : 1}}
                            onClick={() => {
                                if (!disabled) {
                                    question.textColor = c;
                                    questionUpdated && questionUpdated(question);
                                }
                            }}
                        >
                            <div className="colour" style={{backgroundColor: theme.colors[c]}}></div>
                        </div>
                    )}
                </div>
                <span>{question.textColor}</span>
            </div>
        </label>
        <div>
            <label className="form-label">
                <span>Answer type</span>
                <select
                    disabled={disabled}
                    className="form-select"
                    value={question.answerType}
                    onChange={(e) => {
                        question.answerType = e.target.value;
                        questionUpdated && questionUpdated(question);
                    }}
                >
                    <option value="none">None</option>
                    <option value="single_choice">Single choice</option>
                    <option value="multiple_choice">Multiple choice</option>
                    <option value="invitees">Invitee list</option>
                    <option value="free_text">Free text</option>
                </select>
            </label>
        </div>
        {(question.answerType === "single_choice" ||
            question.answerType === "multiple_choice") ?
            <div className="answer-section">
                <h5>Choices</h5>
                <ul className="choice-list list-group">
                    {question.answers && question.answers.map(m =>
                        <li key={m.id} className="list-group-item">
                            {m.id &&
                                <label className="form-label">
                                    Order <AiOutlineArrowUp onClick={() => dispatch(moveAnswerUp(m.id))}/>
                                    <AiOutlineArrowDown onClick={() => dispatch(moveAnswerDown(m.id))}/>
                                </label>
                            }
                            <label className="form-label">
                                <span>Text</span>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={m.answerText || ""}
                                    onChange={(e) => {
                                        m.answerText = e.target.value;
                                        questionUpdated && questionUpdated(question);
                                    }}
                                />
                            </label>
                            <label className="form-label">
                                <span>Help text</span>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={m.helpText || ""}
                                    onChange={(e) => {
                                        m.helpText = e.target.value;
                                        questionUpdated && questionUpdated(question);
                                    }}
                                />
                            </label>
                            {/*<label className="form-label">*/}
                            {/*    <span>Go to</span>*/}
                            {/*    <select value={m.nextQuestion} className="form-select">*/}
                            {/*        {questions && questions.map(q =>*/}
                            {/*            <option*/}
                            {/*                key={q.id}*/}
                            {/*                value={q.id}*/}
                            {/*                onChange={(e) => {*/}
                            {/*                    m.nextQuestion = e.target.value;*/}
                            {/*                    updateQuestion(question);*/}
                            {/*                }}*/}
                            {/*            >{q.heading}</option>*/}
                            {/*        )}*/}
                            {/*    </select>*/}
                            {/*</label>*/}
                        </li>
                    )}
                </ul>
                <button
                    className="btn btn-secondary"
                    onClick={() => {
                        question.answers = (question.answers || []);
                        question.answers.push({});
                        questionUpdated && questionUpdated(question);
                    }}>Add
                </button>
            </div> :
            null}
    </div>
};

export default QuestionPropEditor;
