import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import questionConfig from "../questions.json";
import responsesApi from 'services/responses-api';

export const fetchResponses = createAsyncThunk(
    'responses/fetchAll',
    async (eventId, thunkAPI) => {
        const response = await responsesApi.fetchResponses(eventId);
        return response;
    }
)

const initialState = {
    loaded: false,
    error: false,
    responses: []
}

export const responsesSlice = createSlice({
    name: 'responses',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(fetchResponses.pending, (state, action) => {
            // Add user to the state array
            state.loading = true;
            state.error = false;
        });

        builder.addCase(fetchResponses.fulfilled, (state, action) => {
            // Add user to the state array
            state.loading = false;
            state.error = false;

            state.responses = action.payload.reduce((acc, cur) => {
                acc[cur.guestId] = (acc[cur.guestId] || []);
                acc[cur.guestId].push(cur);
                return acc;
            }, {});
        });

        builder.addCase(fetchResponses.rejected, (state, action) => {
            // Add user to the state array
            state.loading = false;
            state.error = true;

            console.error(action);
        });
    },
})

// Action creators are generated for each case reducer function
export const {
    confirmUser,
    clearUser,
    finish,
    setGuests,
    nextQuestion,
    previousQuestion,
} = responsesSlice.actions

export default responsesSlice.reducer
