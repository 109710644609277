import QuestionEditor from "./QuestionEditor";
import {NavLink, Outlet, useParams} from "react-router-dom";
import {useEffect} from "react";
import {fetchEvent, fetchEvents} from "../redux/events-slice";
import {fetchQuestions} from "../redux/questions-slice";
import {useDispatch, useSelector} from "react-redux";
import {fetchGuests} from "../redux/guests-slice";
import {fetchThemes} from "../redux/theme-slice";
import {fetchResponses} from "../redux/responses-slice";

const Dashboard = () => {
    const dispatch = useDispatch();
    const {eventId} = useParams();

    const state = useSelector((state) => state.rsvp);
    const event = (state.events.events || []).find(f => f.id === eventId);

    const {questions} = state.questions || [];
    const {guests} = state.guests || [];
    const {responses} = state.responses || {};

    useEffect(() => {
        dispatch(fetchEvent(eventId));
        dispatch(fetchQuestions(eventId));
        dispatch(fetchGuests(eventId));
        dispatch(fetchThemes(eventId));
        dispatch(fetchResponses(eventId));
    }, []);

    const activeClassName = " active";
    return <div className="container dashboard">
        <h2>{event?.name}</h2>
        <ul className="nav nav-tabs">
            <li className="nav-item">
                <NavLink className={({isActive}) =>
                    `nav-link${isActive ? activeClassName : ''}`
                } to="summary">Event</NavLink>
            </li>
            <li className="nav-item">
                <NavLink className={({isActive}) =>
                    `nav-link${isActive ? activeClassName : ''}`
                } to="questions">
                    Questions <span className="badge rounded-pill bg-primary">
                    {questions.length}
                </span>
                </NavLink>

            </li>
            <li className="nav-item">
                <NavLink className={({isActive}) =>
                    `nav-link${isActive ? activeClassName : ''}`
                } to="guests">
                    Guests <span className="badge rounded-pill bg-primary">
                    {guests.length}
                </span>
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink className={({isActive}) =>
                    `nav-link${isActive ? activeClassName : ''}`
                } to="responses/summary">
                    Responses <span className="badge rounded-pill bg-primary">
                    {responses && Object.keys(responses).length || 0}
                </span>
                </NavLink>
            </li>
        </ul>

        <Outlet/>
    </div>
}

export default Dashboard;
