import Question from "./Question";

import "./question-editor.scss";
import {useEffect, useState} from "react";
import QuestionPropEditor from "./QuestionPropEditor";
import {useDispatch, useSelector} from "react-redux";
import {fetchQuestions, addQuestion, updateQuestion, saveQuestions, deleteQuestion} from "../redux/questions-slice";
import {resolveColor} from "../services/style-helper";
import {useNavigate, useParams} from "react-router-dom";
import {saveEvents, updateEvent} from "../redux/events-slice";
import {RiDeleteBack2Fill} from "react-icons/ri";
import Modal from "./Modal";
import QuestionPreview from "./QuestionPreview";
import {hover} from "@testing-library/user-event/dist/hover";
import {GridLoader} from "react-spinners";

const QuestionEditor = () => {
    const params = useParams();
    const navigate = useNavigate();

    const {eventId} = params;

    const state = useSelector((state) => state.rsvp);
    const dispatch = useDispatch();

    const [draftQuestion, setDraftQuestion] = useState(null);

    const [questionToDelete, setQuestionToDelete] = useState(null);
    const [showDeleteQuestionModal, setShowDeleteQuestionModal] = useState(false);
    const [hoveredQuestion, setHoveredQuestion] = useState(null);

    const confirmDeleteQuestion = (question) => {
        setQuestionToDelete(question);
        setShowDeleteQuestionModal(true);
    }

    useEffect(() => {
        dispatch(fetchQuestions(eventId));
    }, []);

    const {
        questions: {questions: sourceQuestions, dirty: questionsDirty},
        events: {events}
    } = state;

    const {themes: {themes}} = state;
    const questions = [...(sourceQuestions || []), draftQuestion].filter(f => f);

    // const {styles, pages, questions: sourceQuestions} = questionConfig;
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState();

    // load themes
    const event = (events || []).find(f => f.id === params.eventId);
    const selectedThemeName = event?.theme;
    const selectedTheme = themes && themes.find(f => f.name === selectedThemeName)?.theme;

    const isDirty = questionsDirty || event?.dirty;

    const setSelectedQuestion = (question) => setSelectedQuestionIndex(questions.indexOf(question));
    const selectedQuestion = questions && questions[selectedQuestionIndex];

    const isLoading = state.events.loading || state.questions.loading || state.guests.loading || state.responses.loading || state.themes.loading;

    if (isLoading) {
        return <div className="loading-spinner">
            <GridLoader size="40px" />
        </div>
    }

    return <div className="question-editor-container">
        <div className="question-editor-toolbar">
            <button
                className="btn btn-secondary toolbar-button"
                onClick={() => dispatch(addQuestion())}
            >
                Add question
            </button>
            <label className="form-label">
                Theme
                <select
                    className="form-select"
                    value={selectedThemeName}
                    onChange={(e) => dispatch(updateEvent(
                        {
                            index: events.indexOf(event),
                            event: {
                                ...event, theme: e.target.value
                            }
                        }
                    ))}
                >
                    {themes && [{}, ...themes].map(t => <option key={t.name} value={t.name}>{t.name}</option>)}
                </select>
            </label>
            <button
                className="btn btn-primary toolbar-button"
                onClick={() => {
                    dispatch(saveQuestions({eventId, questions}));
                    dispatch(saveEvents());
                }}
                disabled={!isDirty}
            >
                Save
            </button>
            <button
                className="btn btn-secondary toolbar-button"
                onClick={() => dispatch(fetchQuestions(eventId))}
                disabled={!isDirty}
            >
                Discard
            </button>
            <button
                className="btn btn-success toolbar-button"
                onClick={() => navigate("preview")}
            >
                Preview all
            </button>
        </div>
        <div className="question-editor-panels">
            <div className="question-editor">
                <div className="question-viewer">
                    <ul className="question-list"
                        style={{width: 100 + ((Math.min(questions?.length, 3) - 3) * 30) + "%"}}>
                        {questions && questions.map(m => <>
                                <li className="question"
                                    key={m.id}
                                    onClick={() => setSelectedQuestion(m)}
                                    onMouseEnter={() => setHoveredQuestion(m)}
                                >
                                    <QuestionPreview question={m} theme={selectedTheme}
                                                     selected={m === selectedQuestion}/>

                                    {m === hoveredQuestion &&
                                        <div className="question-tools">
                                            <button className="btn btn-danger" onClick={() => confirmDeleteQuestion(m)}>X
                                            </button>
                                        </div>}
                                </li>
                                <li className={`add-question ${hoveredQuestion === m ? "show" : ""}`}>
                                    {hoveredQuestion && hoveredQuestion === m &&
                                        <button className="btn btn-primary" onClick={() => dispatch(addQuestion(m))}>+
                                        </button>
                                    }
                                </li>
                            </>
                        )}
                        <li className="question"></li>
                    </ul>
                </div>

                <QuestionPropEditor
                    question={selectedQuestion}
                    questions={questions.filter(f => f !== selectedQuestion)}
                    questionUpdated={(updated) =>
                        dispatch(updateQuestion({index: questions.indexOf(selectedQuestion), question: updated})
                        )}
                    theme={selectedTheme}
                />
            </div>
        </div>
        <Modal
            body={<>
                <h2>Delete question</h2>
                <p>Are you sure you want to delete this question?</p>
            </>}
            footer={<button className="btn btn-danger" onClick={() => {
                dispatch(deleteQuestion({eventId, question: questionToDelete}));
                setShowDeleteQuestionModal(false);
                setQuestionToDelete(null);
            }}>Yes, delete</button>}
            isOpen={showDeleteQuestionModal}
            onClose={() => {
                setShowDeleteQuestionModal(false)
                setQuestionToDelete(null);
            }}
            closeLabel="Cancel"
        />
    </div>
}

export default QuestionEditor;
