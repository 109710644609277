import {QRCode} from "react-qrcode-logo";
import Modal from "./Modal";
import {useState} from "react";
import RsvpQrCode from "./RsvpQrCode";

const QrCodeModal = ({show, onClose, settings, onSave}) => {
    const [ecLevel, setEcLevel] = useState(settings?.ecLevel || "L");
    const [style, setStyle] = useState(settings?.style || "squares");
    const [cornerStyle, setCornerStyle] = useState(settings?.cornerStyle || "square");
    const [logoUrl, setLogoUrl] = useState(settings?.logoUrl);
    const [logoSize, setLogoSize] = useState(settings?.logoSize || 20);
    const [logoOpacity, setLogoOpacity] = useState(settings?.logoOpacity || 1);

    return <Modal
        isOpen={show}
        onClose={onClose}
        header={<>
            <h5 className="modal-title">Edit QR Code Style</h5>
        </>}
        body={<>
            <div className="form-group">
                <dl className="row">
                    <dt className="col-sm-4">Error correction</dt>
                    <dd className="col-sm-8">
                        <div className="form-check form-check-inline">
                            <label className="form-check-label">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="ecLevel"
                                    onChange={() => setEcLevel("L")}
                                    checked={ecLevel === "L"}
                                />
                                Low
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <label className="form-check-label">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="ecLevel"
                                    onChange={() => setEcLevel("M")}
                                    checked={ecLevel === "M"}
                                />
                                Medium
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <label className="form-check-label">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="ecLevel"
                                    onChange={() => setEcLevel("H")}
                                    checked={ecLevel === "H"}
                                />
                                High
                            </label>
                        </div>
                    </dd>
                </dl>
            </div>
            <div className="form-group">
                <dl className="row">
                    <dt className="col-sm-4">Style</dt>
                    <dd className="col-sm-8">
                        <div className="form-check form-check-inline">
                            <label className="form-check-label">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="style"
                                    onChange={() => setStyle("squares")}
                                    checked={style === "squares"}
                                />
                                Squares
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <label className="form-check-label">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="style"
                                    onChange={() => setStyle("dots")}
                                    checked={style === "dots"}
                                />
                                Dots
                            </label>
                        </div>
                    </dd>
                </dl>
            </div>
            <div className="form-group">
                <dl className="row">
                    <dt className="col-sm-4">Corner Style</dt>
                    <dd className="col-sm-8">
                        <div className="form-check form-check-inline">
                            <label className="form-check-label">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="cornerStyle"
                                    onChange={() => setCornerStyle("square")}
                                    checked={cornerStyle === "square"}
                                />
                                Square
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <label className="form-check-label">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="cornerStyle"
                                    onChange={() => setCornerStyle("round")}
                                    checked={cornerStyle === "round"}
                                />
                                Round
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <label className="form-check-label">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="cornerStyle"
                                    onChange={() => setCornerStyle("teardrop")}
                                    checked={cornerStyle === "teardrop"}
                                />
                                Teardrop
                            </label>
                        </div>
                    </dd>
                </dl>
            </div>
            <div className="form-group">
                <dl className="row">
                    <dt className="col-sm-4">Logo</dt>
                    <dd className="col-sm-8">
                        <label className="form-label">
                            <input type="text" placeholder="URL" className="form-control"
                                   onChange={(e) => setLogoUrl(e.target.value)} value={logoUrl}/>
                        </label>
                    </dd>
                </dl>
            </div>
            <div className="form-group">
                <dl className="row">
                    <dt className="col-sm-4">Logo size</dt>
                    <dd className="col-sm-8">
                        <label className="form-label">
                            <input type="range" className="form-range" min={0} max={150} value={logoSize}
                                   onChange={(e) => setLogoSize(e.target.value)}/>
                        </label>
                    </dd>
                </dl>
            </div>
            <div className="form-group">
                <dl className="row">
                    <dt className="col-sm-4">Logo opacity</dt>
                    <dd className="col-sm-8">
                        <label className="form-label">
                            <input type="range" className="form-range" min={0} max={100} value={logoOpacity * 100}
                                   onChange={(e) => setLogoOpacity(e.target.value / 100)}/>
                        </label>
                    </dd>
                </dl>
            </div>
            <div className="qr-preview">
                <RsvpQrCode settings={{
                    ecLevel,
                    style,
                    cornerStyle,
                    logoUrl,
                    logoSize,
                    logoOpacity
                }}/>
            </div>
        </>}
        footer={<button className="btn btn-primary" onClick={() => onSave({
            ecLevel,
            style,
            cornerStyle,
            logoUrl,
            logoSize,
            logoOpacity
        })}>Save</button>}
    />
}

export default QrCodeModal;
