import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import responsesApi from 'services/responses-api';
import {v4} from "uuid";

export const fetchGuests = createAsyncThunk(
    'guests/fetchAll',
    async (eventId, thunkAPI) => {
        const response = await responsesApi.fetchGuests(eventId);
        return response;
    }
)

export const saveGuests = createAsyncThunk(
    'guests/save',
    async (eventId, thunkAPI) => {
        const state = thunkAPI.getState().rsvp;

        const response = await responsesApi.saveGuests(eventId, state.guests.guests.filter(f => f.dirty));

        if (response.ok) {
            return response;
        } else {
            return null;
        }
    }
);

export const disableGuest = createAsyncThunk(
    'guests/disable',
    async ({eventId, guestId}, thunkAPI) => {
        const response = await responsesApi.disableGuest(eventId, guestId);
        if (response.ok) {
            return guestId;
        } else {
            return null;
        }
    }
);

export const enableGuest = createAsyncThunk(
    'guests/enable',
    async ({eventId, guestId}, thunkAPI) => {
        const response = await responsesApi.enableGuest(eventId, guestId);
        if (response.ok) {
            return guestId;
        } else {
            return null;
        }
    }
);

export const resetGuest = createAsyncThunk(
    'guests/reset',
    async ({eventId, guestId}, thunkAPI) => {
        const response = await responsesApi.resetGuest(eventId, guestId);
        if (response.ok) {
            return guestId;
        } else {
            return null;
        }
    }
);

export const deleteGuest = createAsyncThunk(
    'guests/delete',
    async ({eventId, guestId}, thunkAPI) => {
        const response = await responsesApi.deleteGuest(eventId, guestId);
        if (response.ok) {
            return guestId;
        } else {
            return null;
        }
    }
);

const initialState = {
    loading: false,
    error: false,
    guests: [],
    dirty: false
}

export const guestsSlice = createSlice({
    name: 'guests',
    initialState,
    reducers: {
        addGuest: (state, action) => {
            state.guests = state.guests || [];
            state.guests.push(action.payload);

            state.dirty = true;
        },
        updateGuest: (state, action) => {
            state.guests = state.guests.filter(f => f.id !== action.payload.guest.id);
            state.guests.push({
                ...action.payload.guest,
                dirty: true
            });
        }
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(fetchGuests.pending, (state, action) => {
            // Add user to the state array
            state.loading = true;
            state.error = false;
        });

        builder.addCase(fetchGuests.fulfilled, (state, action) => {
            // Add user to the state array
            state.loading = false;
            state.error = false;
            state.dirty = false;

            state.guests = action.payload;
        });

        builder.addCase(fetchGuests.rejected, (state, action) => {
            // Add user to the state array
            state.loading = false;
            state.error = true;

            console.error(action);
        });

        builder.addCase(saveGuests.fulfilled, (state, action) => {
            state.guests = state.guests.map(m => ({
                ...m,
                dirty: false
            }));
        });

        builder.addCase(saveGuests.rejected, (state, action) => {
            console.error(action);
        });

        builder.addCase(enableGuest.fulfilled, (state, action) => {
            state.guests.find(f => f.id === action.payload).disabled = false;
        });

        builder.addCase(disableGuest.fulfilled, (state, action) => {
            state.guests.find(f => f.id === action.payload).disabled = true;
        });

        builder.addCase(deleteGuest.fulfilled, (state, action) => {
            state.guests = state.guests.filter(f => f.id !== action.payload);
        });
    },
})

// Action creators are generated for each case reducer function
export const {addGuest, updateGuest} = guestsSlice.actions

export default guestsSlice.reducer
