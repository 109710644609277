const Modal = ({header, body, footer, isOpen, onClose, className, closeLabel, disableControls}) => {
    return <>
        <div className={`modal`} tabIndex="-1" role="dialog" style={{display: isOpen ? "block" : null}}>
            <div className={`modal-dialog ${className}`} role="document">
                <div className="modal-content">
                    {header &&
                        <div className="modal-header">
                            {header}
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={onClose}
                                disabled={disableControls}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    }
                    <div className="modal-body">
                        {body}
                    </div>
                    <div className="modal-footer">
                        {footer}
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={onClose}
                            disabled={disableControls}
                        >{closeLabel || "Close"}</button>
                    </div>
                </div>
            </div>
        </div>
        {isOpen && <div className={`modal-backdrop fade show`}></div>}
    </>
};

export default Modal;
