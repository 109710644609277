import Papa from "papaparse";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    addGuest,
    deleteGuest,
    disableGuest,
    enableGuest,
    fetchGuests,
    resetGuest,
    saveGuests
} from "../redux/guests-slice";
import {Link, useParams} from "react-router-dom";
import responsesApi from "../services/responses-api";
import {TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted} from "react-icons/ti";
import {GridLoader} from "react-spinners";

const Guests = () => {
    const {eventId} = useParams();

    const state = useSelector(state => state.rsvp);
    const {guests: {guests}, responses: {responses}} = state;
    const event = (state.events.events || []).find(f => f.id === eventId);

    const dispatch = useDispatch();

    const [sortColumn, setSortColumn] = useState("name");
    const [sortHandler, setSortHandler] = useState(() => () => 0);
    const [sortAscending, setSortAscending] = useState(true);

    const [filterType, setFilterType] = useState("all");
    const [filterHandler, setFilterHandler] = useState(() => () => true);

    const [selectedGuests, setSelectedGuests] = useState([]);

    const sortedGuests = guests && [...guests].map(m => ({
        ...m,
        responded: !!(responses && Object.keys(responses).find(f => f === m.id))
    })).sort(sortHandler) || [];

    const filteredGuests = sortedGuests.filter(filterHandler);

    const sortGuests = (prop) => {
        let ascending = sortAscending;
        if (prop === sortColumn) {
            setSortAscending(!sortAscending);
            ascending = !sortAscending;
        } else {
            ascending = true;
            setSortAscending(ascending);
            setSortColumn(prop);
        }

        const up = ascending ? -1 : 1;
        const down = ascending ? 1 : -1;

        setSortHandler(() => (a, b) => {
            if (a[prop] < b[prop]) {
                return up;
            }
            if (a[prop] > b[prop]) {
                return down;
            }
            return 0;
        });
    }

    const filterGuests = (type) => {
        setFilterType(type);

        switch (type) {
            case "responded":
                setFilterHandler(() => (g) => g.responded);
                break;
            case "remaining":
                setFilterHandler(() => (g) => !g.responded);
                break;
            case "disabled":
                setFilterHandler(() => (g) => g.disabled);
                break;
            default:
                setFilterHandler(() => () => true);
                break;
        }
    }

    const selectAllGuests = () => {
        setSelectedGuests(guests.map(m => m.id));
    }

    const deselectAllGuests = () => {
        setSelectedGuests([]);
    }

    const toggleGuestSelection = (guestId) => {
        if (selectedGuests.includes(guestId)) {
            setSelectedGuests(selectedGuests.filter(f => f !== guestId));
        } else {
            setSelectedGuests([...selectedGuests, guestId]);
        }
    }

    const generateCsv = async () => {
        window.open(await responsesApi.downloadGuestsCsv(eventId, selectedGuests.length > 0 ? selectedGuests : guests.map(m => m.id)));
    }

    const parseCsv = (event) => {
        const file = event.target.files[0];

        if (file) {
            Papa.parse(file, {
                complete: function (results) {
                    const columns = results.data[0];
                    const nameColumn = columns.find(f => f.toLowerCase() === "name");
                    const nameIndex = nameColumn && columns.indexOf(nameColumn);
                    const emailColumn = columns.find(f => f.toLowerCase() === "email");
                    const emailIndex = emailColumn && columns.indexOf(emailColumn);

                    if (nameIndex > -1) {
                        const guests = results.data.slice(1).map(m => ({
                            name: m[nameIndex],
                            email: emailIndex && emailIndex > -1 && m[emailIndex],
                            dirty: true
                        }));

                        guests.filter(f => f.name).forEach(m => dispatch(addGuest(m)));
                    }
                }
            });
        }
    }

    const isLoading = state.events.loading || state.questions.loading || state.guests.loading || state.responses.loading || state.themes.loading;

    if (isLoading) {
        return <div className="loading-spinner">
            <GridLoader size="40px" />
        </div>
    }

    return <div className="guests-container">
        <h3>Upload guests</h3>
        <div className="toolbar">
            <div className="file-upload">
                <input className="form-control" type="file" onChange={parseCsv}/>
            </div>
            <button className="btn btn-secondary" onClick={() => generateCsv()}>Download CSV</button>
            <button
                className="btn btn-info"
                disabled={selectedGuests.length === 0}
                onClick={() => selectedGuests.forEach(g => dispatch(disableGuest({eventId, guestId: g})))}
            >
                Disable Selected ({selectedGuests.length})
            </button>
            <button
                className="btn btn-success"
                disabled={selectedGuests.length === 0}
                onClick={() => selectedGuests.forEach(g => dispatch(enableGuest({eventId, guestId: g})))}
            >
                Enable Selected ({selectedGuests.length})
            </button>
            <button
                className="btn btn-warning"
                disabled={selectedGuests.length === 0}
                onClick={() => selectedGuests.forEach(g => dispatch(resetGuest({eventId, guestId: g})))}
            >
                Reset Selected ({selectedGuests.length})
            </button>
            <button
                className="btn btn-danger"
                disabled={selectedGuests.length === 0}
                onClick={() => selectedGuests.forEach(g => dispatch(deleteGuest({eventId, guestId: g})))}
            >
                Delete selected ({selectedGuests.length})
            </button>
        </div>

        {guests.filter(f => f.dirty).length > 0 &&
            <div className="alert alert-info toolbar">
                {guests.filter(f => f.dirty).length} changes to save
                <button className="btn btn-primary" onClick={() => dispatch(saveGuests(eventId))}>Save</button>
                <button className="btn btn-secondary" onClick={() => dispatch(fetchGuests(eventId))}>Discard</button>
            </div>
        }

        {/*<div className="guest-summary">*/}
        <div className="btn-group" role="group" aria-label="Guest filters">
            <input id="all-filter" type="radio" className="btn-check" name="guest-filter-radio"
                   checked={filterType === "all"} onChange={() => filterGuests("all")}/>
            <label className="btn btn-outline-primary" htmlFor="all-filter">
                <h1>{sortedGuests.length}</h1>
                <h5 className="card-title">Total guests</h5>
            </label>

            <input id="responded-filter" type="radio" className="btn-check" name="guest-filter-radio"
                   checked={filterType === "responded"} onChange={() => filterGuests("responded")}/>
            <label className="btn btn-outline-primary" htmlFor="responded-filter">
                <h1>{sortedGuests.filter(f => f.responded).length}</h1>
                <h5 className="card-title">Responded</h5>
            </label>

            <input id="remaining-filter" type="radio" className="btn-check" name="guest-filter-radio"
                   checked={filterType === "remaining"} onChange={() => filterGuests("remaining")}/>
            <label className="btn btn-outline-primary" htmlFor="remaining-filter">

                <h1>{sortedGuests.filter(f => !f.responded).length - sortedGuests.filter(f => f.disabled && !f.responded).length}</h1>
                <h5 className="card-title">Left to respond</h5>
            </label>

            <input id="disabled-filter" type="radio" className="btn-check" name="guest-filter-radio"
                   checked={filterType === "disabled"} onChange={() => filterGuests("disabled")}/>
            <label className="btn btn-outline-primary" htmlFor="disabled-filter">
                <h1>{sortedGuests.filter(f => f.disabled).length}</h1>
                <h5 className="card-title">Disabled</h5>
            </label>
        </div>

        <table className="table table-sm table-bordered table-striped">
            <thead>
            <tr>
                <tr scope="col">
                    <input
                        type="checkbox"
                        checked={selectedGuests.length === guests.length}
                        onClick={() => selectedGuests.length === guests.length ? deselectAllGuests() : selectAllGuests()}
                    />
                </tr>
                <th scope="col" className="text-center">#
                    {/*<TiArrowUnsorted*/}
                    {/*    onClick={() => sortOn((a, b) => a.name < b.name ? -1 : a.name === b.name ? 0 : 1)}/>*/}
                </th>
                {
                    [
                        {prop: "name", name: "Name"},
                        {prop: "email", name: "Email"},
                        {prop: "responded", name: "Responded"},
                        {prop: "disabled", name: "Disabled"},
                        {prop: "sessionId", name: "Personal URL"}
                    ].map(m =>
                        <th scope="col" onClick={() => sortGuests(m.prop)}>{m.name}
                            {sortColumn === m.prop && sortAscending && <TiArrowSortedUp/>}
                            {sortColumn === m.prop && !sortAscending && <TiArrowSortedDown/>}
                            {sortColumn !== m.prop && <TiArrowUnsorted/>}
                        </th>)
                }
            </tr>
            </thead>
            <tbody>
            {
                filteredGuests.map((m, i) =>
                    <tr key={m.id}
                        className={`${m.dirty && 'table-info'} ${selectedGuests.find(f => f === m.id) && 'table-primary'}`}>
                        <td>
                            <input
                                type="checkbox"
                                checked={selectedGuests.find(f => f === m.id)}
                                onClick={() => toggleGuestSelection(m.id)}
                            />
                        </td>
                        <th className="text-center" scope="row">{i + 1}</th>
                        <td>{m.name}</td>
                        <td>{m.email}</td>
                        <td>{m.responded ? "Yes" :
                            <span className="text-muted">No</span>}</td>
                        <td>{m.disabled ? "Yes" : <span className="text-muted">No</span>}</td>
                        <td>
                            <a href={`https://${event?.customDomain}?session=${m.sessionId}`}>
                                {`https://${event?.customDomain}?session=${m.sessionId}`}
                            </a>
                        </td>
                    </tr>
                )
            }
            </tbody>
        </table>
    </div>;
};

export default Guests;
