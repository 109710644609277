import {ReactComponent as Logo} from "assets/images/monogram-new.svg";
import {useDispatch, useSelector} from "react-redux";
import {resolveColor} from "../services/style-helper";
import {useEffect, useMemo, useState} from "react";
import {fetchResponses} from "../redux/responses-slice";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import moment from "moment";
import {TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted} from "react-icons/ti";
import ReactSelect, {components} from "react-select";
import {IoMdRemoveCircle} from "react-icons/io";
import {ImCross} from "react-icons/im";
import {GridLoader} from "react-spinners";

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const ValueContainer = (props) => {
    const length = props.getValue().length;
    const children = props.children;

    return length ? (
        <components.ValueContainer {...props}>
            <>
                {length} selected
                {children.slice(-1)}
            </>
        </components.ValueContainer>
    ) : (
        <components.ValueContainer {...props}>{children}</components.ValueContainer>
    );
};

const ResponsesSummary = () => {
    const {eventId} = useParams();
    const state = useSelector((state) => state.rsvp);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [columnFilters, setColumnFilters] = useState({});

    const responses = state.responses?.responses || [];
    const questions = state.questions?.questions;
    const guests = state.guests?.guests;

    const questionsToDisplay = questions?.filter(f => !!f.answerType && f.answerType !== "none");

    const sortedResponses = responses && guests && Object.keys(responses)
        .map(key => ({
            guestName: guests.find(f => f.id === key)?.name,
            key,
            ...questionsToDisplay.reduce((acc, cur) => {
                acc[cur.id] = responses[key].find(f => f.questionId === cur.id)?.answerText;
                return acc;
            }, {})
        }));

    const filteredResponses = sortedResponses.filter(f => {
        const props = Object.keys(f);

        for (let prop of props) {
            if (columnFilters[prop] &&
                columnFilters[prop].find(c => c.value === f[prop])
            ) {
                return false;
            }
        }

        return true;
    });

    const flattenedResponses = Object.keys(responses || {})
        .flatMap(f => responses[f])
        .reduce((acc, cur) => {
            let answers = acc[cur.questionId] || {};
            if (cur.answerId) {
                answers[cur.answerId] = (answers[cur.answerId] || 0) + 1;
            } else if (cur.answerText) {
                answers = (Array.isArray(answers) ? answers : []);
                answers.push(cur.answerText);
            }

            return {
                ...acc,
                [cur.questionId]: answers
            };
        }, {});

    //
    // const filterOptions = useMemo(() =>
    //         [{id: "timestamp"}].concat(questionsToDisplay).reduce((obj, m) => {
    //             obj = {
    //                 ...obj,
    //                 [m.id]: Object.keys(sortedResponses.filter(f => f[m.id]).reduce((acc, cur) => ({
    //                     ...acc,
    //                     [cur[m.id]]: cur[m.id]
    //                 }), {}))
    //                     .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    //                     .concat(["(blank)"])
    //                     .map(m => ({value: m, label: m}))
    //             }
    //
    //             return obj;
    //         }, {})
    //     , [responses, questionsToDisplay]);

    const downloadResponses = () => {
        // download responses as a csv
        const csv = [
            ["Guest Name", "Date", ...questionsToDisplay.map(f =>
                `"${f.heading}"`
            )]
        ].concat(filteredResponses.map(f => [f.guestName, f.timestamp, ...questionsToDisplay.map(q => `"${f[q.id] || ""}"`)]));

        const csvContent = "data:text/csv;charset=utf-8," + csv.map(e => e.join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);

        window.open(encodedUri);
    }

    useEffect(() => {
        dispatch(fetchResponses(eventId));
    }, [])

    const isLoading = state.events.loading || state.questions.loading || state.guests.loading || state.responses.loading || state.themes.loading;

    return <div className="responses-container">
        <div className="toolbar">
            <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio1"
                    autoComplete="off"
                    checked={true}
                />
                <label
                    className="btn btn-outline-primary"
                    htmlFor="btnradio1"
                >Summary</label>

                <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio2"
                    autoComplete="off"
                    checked={false}
                    onChange={() => navigate("../detail")}
                />
                <label
                    className="btn btn-outline-primary"
                    htmlFor="btnradio2"
                >Detailed</label>
            </div>

            <button className="btn btn-secondary" onClick={() => downloadResponses()}>Download current view as
                CSV
            </button>
        </div>

        <div className="filter-bar">
            <span>Filter</span>
            <div className="card filters">
                {Object.keys(columnFilters).flatMap(m => columnFilters[m]).length === 0 &&
                    <div>To filter this view, click on some results below to include them.</div>}
                {Object.keys(columnFilters).flatMap(m => columnFilters[m]).map(filter =>
                    <div className="filter">
                        {filter.label.length > 20 ? `${filter.label.substring(0, 20)}...` : filter.label}
                        <ImCross/>
                    </div>
                )}
            </div>
        </div>

        {isLoading ?
            <div className="loading-spinner">
                <GridLoader size="40px"/>
            </div>
            : <div className="stats">
                {questionsToDisplay.map(question => <div className="question-response">
                        <h2>{question.heading}</h2>
                        <h5>{question.subHeading}</h5>
                        <p>{question.body}</p>
                        <div className="responses">
                            {question.answers.length > 0 && question.answers.map(answer => <div className="card">
                                <div className="card-body" onClick={() => setColumnFilters({
                                    ...columnFilters,
                                    [question.id]: (columnFilters[question.id] || []).concat({
                                        key: answer.id,
                                        label: answer.answerText
                                    })
                                })}>
                                    <h1>
                                        {flattenedResponses[question.id] && flattenedResponses[question.id][answer.id] || 0}
                                    </h1>
                                    <h5 className="card-title">
                                        {answer.answerText}
                                    </h5>
                                </div>
                            </div>)}
                            {question.answerType === "free_text" &&
                                flattenedResponses[question.id] &&
                                Array.isArray(flattenedResponses[question.id]) &&
                                flattenedResponses[question.id]
                                    .filter(f => f)
                                    .reduce((acc, cur) => {
                                        const count = acc.find(f => f.answer.toLowerCase() === cur.toLowerCase().trim()) || {
                                            answer: cur.trim(),
                                            count: 0
                                        };
                                        count.count = count.count + 1;

                                        return [
                                            ...acc.filter(f => f !== count),
                                            count
                                        ]
                                    }, [])
                                    .map(m =>
                                        <div className="alert alert-secondary" role="alert">
                                            {m.answer} {m.count > 1 &&
                                            <span className="badge text-bg-danger">{m.count}</span>}
                                        </div>)}
                        </div>
                        <hr/>
                    </div>
                )}
            </div>
        }
    </div>
};

export default ResponsesSummary;
