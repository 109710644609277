import {useEffect, useRef, useState} from "react";

export default function useResize(elRef) {
    const [size, setSize] = useState({width: 0, height: 0})

    const observer = useRef(
        new ResizeObserver(entries => {
            // Only care about the first element, we expect one element ot be watched
            const {width, height} = entries[0].contentRect;

            setSize({width, height});
        })
    )

    useEffect(() => {
        if (elRef.current) {
            observer.current.observe(elRef.current);
        }

        return () => {
            if (elRef.current)
                observer.current.unobserve(elRef.current);
        }
    }, [elRef.current, observer]);

    return size
}
