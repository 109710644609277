import Modal from "./Modal";
import {useState} from "react";

const EditThemeModal = ({show, onClose, onSave}) => {

    return <Modal
        isOpen={show}
        onClose={onClose}
        closeLabel="Cancel"
        header={<>
            <h5 className="modal-title">Edit Event Contact Information</h5>
        </>}
        body={<>

        </>}
        footer={<button className="btn btn-primary" onClick={() => onSave({

        })}>Save</button>}
    />
}

export default EditThemeModal;
