import Modal from "./Modal";
import {useState} from "react";
import WelcomeSignedIn from "./WelcomeSignedIn";

const EditWelcomePageModal = ({show, onClose, onSave, theme, background, color, event}) => {
    const [backgroundColor, setBackgroundColor] = useState(background);
    const [textColor, setTextColor] = useState(color);

    return <Modal
        isOpen={show}
        onClose={onClose}
        closeLabel="Cancel"
        header={<>
            <h5 className="modal-title">Edit Event Contact Information</h5>
        </>}
        body={<>
            <div className="welcome-page-preview" style={{width: "60%"}}>
                {theme &&
                    <WelcomeSignedIn user={{current: {name: "Test Smith"}}}
                                     config={{
                                         theme: {
                                             ...theme,
                                             welcomePage: {
                                                 ...theme.welcomePage,
                                                 background: backgroundColor,
                                                 color: textColor
                                             }
                                         }
                                     }}
                                     logo={event.logo}/>
                }
            </div>
            <div className="form-group" style={{width: "50%"}}>
                <label className="form-label">
                    <span>Background colour</span>
                </label>

                <div className="theme-preview">
                    <div className="colours inline selectable">
                        {theme && theme.colors && Object.keys(theme.colors).filter(f => f).map(c =>
                            <div
                                className={`theme-colour${backgroundColor === c ? " selected" : ""}`}
                                onClick={() => {
                                    setBackgroundColor(c)
                                }}
                            >
                                <div className="colour" style={{backgroundColor: theme.colors[c]}}></div>
                            </div>
                        )}
                    </div>
                    <span>{backgroundColor}</span>
                </div>
            </div>
            <div className="form-group" style={{width: "50%"}}>
                <label className="form-label">
                    <span>Text colour</span>
                </label>

                <div className="theme-preview">
                    <div className="colours inline selectable">
                        {theme && theme.colors && Object.keys(theme.colors).filter(f => f).map(c =>
                            <div
                                className={`theme-colour${textColor === c ? " selected" : ""}`}
                                onClick={() => {
                                    setTextColor(c)
                                }}
                            >
                                <div className="colour" style={{backgroundColor: theme.colors[c]}}></div>
                            </div>
                        )}
                    </div>
                    <span>{textColor}</span>
                </div>
            </div>
        </>}
        footer={<button className="btn btn-primary" onClick={() => onSave({
            welcomePage: {
                background: backgroundColor,
                color: textColor
            }
        })}>Save</button>}
    />
}

export default EditWelcomePageModal;
