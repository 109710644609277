// Introducing the hassle-free RSVP management system for your nuptials! You can easily keep track of visitor replies, dietary restrictions, and any other significant information on our online platform. With just a few clicks, you can see who is coming, who isn't, and who hasn't yet replied, eliminating the need for chasing down RSVP cards or spending hours on the phone. Additionally, you can gather all the data you require from your guests using our customizable RSVP form, guaranteeing a stress-free and well-organized wedding planning process. Try our tool right away to say "I do" to the RSVP management of the future.
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {fetchEvents, saveEvents, updateEvent, update} from "../redux/events-slice";
import {useEffect, useRef, useState} from "react";
import {TiTick} from "react-icons/ti";
import {QRCode} from 'react-qrcode-logo';
import QrCodeModal from "./QrCodeModal";
import RsvpQrCode from "./RsvpQrCode";
import WelcomeSignedIn from "./WelcomeSignedIn";
import useResize from "./ResizeObserverHook";
import EventContactModal from "./EventContactModal";
import EditThemeModal from "./EditThemeModal";

import {ReactComponent as Logo} from "assets/images/monogram-new.svg";
import EditLogoModal from "./EditLogoModal";
import EditWelcomePageModal from "./EditWelcomePageModal";
import {BarLoader, CircleLoader, GridLoader} from "react-spinners";
import {fetchThemes, updateTheme} from "../redux/theme-slice";

const EventSummary = () => {
    const {eventId} = useParams();
    const state = useSelector((state) => state.rsvp);
    const dispatch = useDispatch();

    const [calculatedZoom, setCalculatedZoom] = useState(1);

    const [showEditQrCode, setShowEditQrCode] = useState(false);
    const [showEditWelcomeScreen, setShowEditWelcomeScreen] = useState(false);
    const [showEditEventContact, setShowEditEventContact] = useState(false);
    const [showEditTheme, setShowEditTheme] = useState(false);
    const [showEditLogo, setShowEditLogo] = useState(false);

    const previewRef = useRef();

    const size = useResize(previewRef);

    const calculateZoom = () => {
        setCalculatedZoom(previewRef.current?.offsetWidth / 500);
    };

    useEffect(() => {
        calculateZoom();
    });


    useEffect(() => {
        if (!state?.events?.saving) {
            setShowEditLogo(false);
        }
    }, [state?.events?.saving]);

    const {questions} = state.questions || [];
    const {guests} = state.guests || [];
    const event = (state.events.events || []).find(f => f.id === eventId);
    const {responses} = state.responses || {};

    const {themes: {themes}} = state;
    const selectedThemeName = event?.theme;
    const selectedTheme = themes && themes.find(f => f.name === selectedThemeName);

    const isLoading = state.events.loading || state.questions.loading || state.guests.loading || state.responses.loading || state.themes.loading;

    if (isLoading) {
        return <div className="loading-spinner">
            <GridLoader size="40px"/>
        </div>
    }

    return event ? <div className="dashboard-home-page">
        <div className="stats">
            <div className="card">
                <div className="card-body">
                    <h2 className="card-title">Custom Domain</h2>
                    <h5>{event.customDomain}<span className="icon-green-tick"><TiTick/></span></h5>
                    <button className="btn btn-primary" disabled={true}>Edit</button>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <h2 className="card-title">QR Code Style</h2>
                    <div>
                        <RsvpQrCode settings={event.qrSettingsJson}/>
                    </div>
                    <button className="btn btn-primary" onClick={() => setShowEditQrCode(true)}>Edit</button>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <h2 className="card-title">Logo</h2>
                    <p>Specify an optional logo to use on your welcome screens</p>
                    <div className="logo-preview">
                        {!!event.logo ? <img src={event.logo} alt="logo"/> :
                            <span className="text-muted">No logo defined</span>}
                    </div>
                    <button className="btn btn-primary" onClick={() => setShowEditLogo(true)}>Edit</button>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <h2>Themes</h2>
                    {themes && themes.map(theme => <>
                        <p>{theme.name}</p>
                        <div className="theme-preview">
                            <div className="colours inline">
                                {Object.keys(theme.theme.colors).map((key, index) =>
                                    <div className={`theme-colour`}>
                                        <div className="colour"
                                             style={{backgroundColor: theme.theme.colors[key]}}>

                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>)}
                    <button disabled className="btn btn-primary" onClick={() => setShowEditWelcomeScreen(true)}>Edit
                    </button>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <h2 className="card-title">Contact Details</h2>
                    <p>These will be shown to users encountering errors when accessing the RSVP site.</p>
                    <dl className="row contact-details-list">
                        <dt className="col-sm-3">Name:</dt>
                        <dd className="col-sm-9">{event.contactName || "(not set)"}</dd>
                        <dt className="col-sm-3">Phone:</dt>
                        <dd className="col-sm-9">{event.contactNumber || "(not set)"}</dd>
                        <dt className="col-sm-3">Email:</dt>
                        <dd className="col-sm-9">{event.contactEmail || "(not set)"}</dd>
                    </dl>
                    <button className="btn btn-primary" onClick={() => setShowEditEventContact(true)}>Edit</button>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <h2>Welcome Page</h2>
                    <div className="welcome-page-preview" ref={previewRef}>
                        <div style={{zoom: calculatedZoom}}>
                            {selectedTheme &&
                                <WelcomeSignedIn user={{current: {name: "Test Smith"}}}
                                                 config={{theme: selectedTheme.theme}} logo={event.logo}/>
                            }
                        </div>
                    </div>
                    <button className="btn btn-primary" onClick={() => setShowEditWelcomeScreen(true)}>Edit
                    </button>
                </div>
            </div>
        </div>

        <QrCodeModal
            show={showEditQrCode}
            settings={event.qrSettingsJson}
            onClose={() => setShowEditQrCode(false)}
            onSave={(settings) => {
                dispatch(updateEvent({
                    eventId, event: {
                        ...event,
                        qrSettingsJson: settings
                    }
                }));

                dispatch(saveEvents());

                setShowEditQrCode(false);
            }}/>

        <EventContactModal
            show={showEditEventContact}
            onClose={() => setShowEditEventContact(false)}
            defaultContactEmail={event.contactEmail}
            defaultContactName={event.contactName}
            defaultContactNumber={event.contactNumber}
            onSave={(contactInfo) => {
                dispatch(updateEvent({
                    eventId, event: {
                        ...event,
                        ...contactInfo,
                    }
                }));

                dispatch(saveEvents());

                setShowEditEventContact(false);
            }}/>

        <EditThemeModal/>

        {showEditWelcomeScreen &&
            <EditWelcomePageModal
                show={showEditWelcomeScreen}
                onClose={() => setShowEditWelcomeScreen(false)}
                theme={selectedTheme.theme}
                event={event}
                background={selectedTheme?.theme?.welcomePage?.background}
                color={selectedTheme?.theme?.welcomePage?.color}
                onSave={(data) => {
                    dispatch(updateTheme({
                        eventId,
                        themeId: selectedTheme.id,
                        theme: {
                            ...selectedTheme.theme,
                            welcomePage: {
                                ...selectedTheme.theme.welcomePage,
                                ...data.welcomePage
                            }
                        }
                    }));

                    setShowEditWelcomeScreen(false);
                }}
            />
        }

        {showEditLogo &&
            <EditLogoModal
                show={showEditLogo}
                onClose={() => setShowEditLogo(false)}
                onSave={(logo) => {
                    dispatch(updateEvent({
                        eventId, event: {
                            ...event,
                            logo
                        }
                    }));

                    dispatch(saveEvents());
                }}
                defaultImage={event.logo}
            />
        }

    </div> : null;
}

export default EventSummary;
