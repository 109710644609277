import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import responsesApi from 'services/responses-api';
import {v4} from "uuid";

export const fetchEvent = createAsyncThunk(
    'events/fetch',
    async (eventId, thunkAPI) => {
        const response = await responsesApi.fetchEvent(eventId);
        return response;
    }
);

export const fetchEvents = createAsyncThunk(
    'events/fetchAll',
    async (eventId, thunkAPI) => {
        const response = await responsesApi.fetchEvents();
        return response;
    }
)

export const saveEvents = createAsyncThunk(
    'events/save',
    async (arg, thunkAPI) => {
        const state = thunkAPI.getState().rsvp;

        return await Promise.all(
            state.events.events.filter(f => f.dirty)
                .map(async (event) => {
                    const response = await responsesApi.saveEvent(event.id, event);
                    if (response.ok) {
                        return event;
                    } else {
                        return null;
                    }
                })
        );
    }
);

export const saveQrSettings = createAsyncThunk(
    'events/saveQrSettings',
    async (arg, thunkAPI) => {

    }
);

const initialState = {
    loading: false,
    saving: false,
    error: false,
    events: [],
    dirty: false
}

export const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        addEvent: (state, action) => {
            state.events = state.events || [];
            state.events.push({});

            state.dirty = true;
        },
        updateEvent: (state, action) => {
            state.events = state.events.filter(f => f.id !== action.payload.event.id);
            state.events.push({
                ...action.payload.event,
                dirty: true
            });
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchEvent.pending, (state, action) => {
            // Add user to the state array
            state.loading = true;
            state.error = false;
        });

        builder.addCase(fetchEvent.fulfilled, (state, action) => {
            // Add event to the state array
            state.loading = false;
            state.error = false;

            state.events = state.events.filter(f => f.id !== action.payload.id);
            state.events.push(action.payload);
        });

        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(fetchEvents.pending, (state, action) => {
            // Add user to the state array
            state.loading = true;
            state.error = false;
        });

        builder.addCase(fetchEvents.fulfilled, (state, action) => {
            // Add user to the state array
            state.loading = false;
            state.error = false;
            state.dirty = false;

            state.events = action.payload;
        });

        builder.addCase(fetchEvents.rejected, (state, action) => {
            // Add user to the state array
            state.loading = false;
            state.error = true;

            console.error(action);
        });

        builder.addCase(saveEvents.pending, (state, action) => {
            state.saving = true;
            state.error = false;
        });

        builder.addCase(saveEvents.fulfilled, (state, action) => {
            state.saving = false;
            state.dirty = false;

            state.events = action.payload;
        });

        builder.addCase(saveEvents.rejected, (state, action) => {
            state.saving = false;
            state.error = true;
        });
    },
})

// Action creators are generated for each case reducer function
export const {addEvent, updateEvent} = eventsSlice.actions

export default eventsSlice.reducer
